import Cookies from 'universal-cookie';
const cookies = new Cookies();
const env = process.env.REACT_APP_ENV;
const jwtCookieName = `bb-auth-${env}`;

export const persistJwt = token => {
  try {
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 1);
    cookies.set(jwtCookieName, token, {
      path: '/',
      secure: env !== 'local' ? true : false,
      sameSite: 'strict',
      expires: expires
    });
  } catch (err) {
    console.log('set cookie err', err);
  }
};

export const getJwt = () => {
  return cookies.get(jwtCookieName)
};

export const isAuthenticated = () => {
  const token = getJwt();
  return (token !== undefined);
};

export const isAuthorised = (authorisedUserTypes, user) => {
  let authorised = true;
  if (authorisedUserTypes && user) {
    authorised = authorisedUserTypes.indexOf(user.type) > -1;
  }
  return authorised;
};

export const logout = () => {
  cookies.remove(jwtCookieName, {path: '/'});
};
