import _ from 'lodash';
import { arrayRotate } from './global.js';

const cardRankings = [
  { value: 'A', sort: 0 },
  { value: 'K', sort: 1 },
  { value: 'Q', sort: 2 },
  { value: 'J', sort: 3 },
  { value: 'T', sort: 4 },
  { value: '9', sort: 5 },
  { value: '8', sort: 6 },
  { value: '7', sort: 7 },
  { value: '6', sort: 8 },
  { value: '5', sort: 9 },
  { value: '4', sort: 10 },
  { value: '3', sort: 11 },
  { value: '2', sort: 12 }
];

const seatRankings = [
  { value: 'N', rank: 0 },
  { value: 'E', rank: 1 },
  { value: 'S', rank: 2 },
  { value: 'W', rank: 3 }
];

const spades = ['2S', '3S', '4S', '5S', '6S', '7S', '8S', '9S', 'TS', 'JS', 'QS', 'KS', 'AS'];
const hearts = ['2H', '3H', '4H', '5H', '6H', '7H', '8H', '9H', 'TH', 'JH', 'QH', 'KH', 'AH'];
const clubs = ['2C', '3C', '4C', '5C', '6C', '7C', '8C', '9C', 'TC', 'JC', 'QC', 'KC', 'AC'];
const diamonds = ['2D', '3D', '4D', '5D', '6D', '7D', '8D', '9D', 'TD', 'JD', 'QD', 'KD', 'AD'];
export const allCardsBySuit = [
  spades.reverse(),
  hearts.reverse(),
  diamonds.reverse(),
  clubs.reverse()
];
export const allCards = _.concat(
  allCardsBySuit[0],
  allCardsBySuit[1],
  allCardsBySuit[2],
  allCardsBySuit[3]
);

// used by comments to replace cards that are typed (in the format {suit}{rank})
// with a highlighted version
export const allCardsForComments = () => {
  let cards = [];
  ['S', 'H', 'C', 'D'].forEach(suit => {
    cardRankings.forEach(rank => {
      cards.push(`${suit}${rank.value}`);
    })
  });
  return cards;
};

export const getSuitFromCardCode = (card) => {
  if (card.length === 2) {
    return card.substring(1, 2);
  } else if (card.length === 3) {
    return card.substring(1, 3);
  }
  throw new Error('card can only have 2 or 3 characters - ' + card);
};

export const getRankFromCardCode = (card) => {
  return card.substring(0, 1);
};

export const sortCards = (cards) => {
  let sortedCards = [];
  // convert incoming cards to ranked cards
  cards.map(card => {
    const rankedCard = _.find(cardRankings, { 'value': card.rank });
    card.sort = rankedCard.sort;
    sortedCards.push(card);
    return sortedCards;
  });
  // sort the rankedCards list by rank
  sortedCards = _.sortBy(sortedCards, ['sort']);
  return sortedCards;
};

export const sortCardsBySuit = (cards) => {
  let sortedBySuit = [];
  const suits = ['S', 'H', 'C', 'D'];
  suits.forEach(suit => {
    const cardsForSuit = getCardsForSuit(cards, suit);
    const sortedCards = sortCards(cardsForSuit);
    sortedBySuit = sortedBySuit.concat(sortedCards);
  });
  return sortedBySuit;
};

export const convertCodeToCardObject = (card, seat) => {
  // convert the card code used by the card component to
  // the full card object used by the hand json
  // e.g. JS to {"suit":"S","rank":"J"}
  let cardObj = {
    'rank': card.substring(0, 1),
    'suit': card.substring(1, 2)
  };
  if (seat) {
    cardObj.seat = seat;
  }
  return cardObj;
};

export const convertCardObjectToCode = (card) => {
  return card.rank + card.suit;
};

export const isCardInArray = (cards, card) => {
  const foundCard = _.find(cards, { suit: card.suit, rank: card.rank });
  return !(foundCard === undefined);
};

export const getCardsForSeat = (cards, seat) => {
  const cardsForSeat = _.filter(cards, { 'seat': seat });
  // return sortCards(cardsForSeat);
  return sortCardsBySuit(cardsForSeat);
};

export const getCardsForSuit = (cards, suit) => {
  const cardsForSuit = _.filter(cards, { 'suit': suit });
  return sortCards(cardsForSuit);
};

export const removeCardFromArray = (cards, card) => {
  let index = 0;
  if (_.isObject(card)) {
    index = _.findIndex(cards, {suit: card.suit, rank: card.rank});
  } else {
    index = cards.indexOf(card);
  }
  cards.splice(index, 1);
  return cards;
};

export const getNextSeatForDeal = (cards, activeSeat) => {
  const countForCurrentHand = getCardsForSeat(cards, activeSeat).length;
  if (countForCurrentHand === 13) {
    // when a hand has 13 cards we need to move to the next hand that has space
    let activeSeatRank = _.find(seatRankings, { value: activeSeat }).rank;
    var offset = activeSeatRank;
    for (let i = 0; i < seatRankings.length; i++) {
      var pointer = (i + offset) % seatRankings.length;
      // count the cards for this seat
      const count = getCardsForSeat(cards, seatRankings[pointer].value).length;
      if (count < 13 && seatRankings[pointer].value !== activeSeat) {
        return seatRankings[pointer].value;
      }
    }
    throw new Error('cant find next seat');
  }
  return activeSeat;
};

export const getNextSeatForPlay = (activeSeat) => {
  let activeSeatRank = _.find(seatRankings, { value: activeSeat }).rank;
  var offset = activeSeatRank;
  for (let i = 0; i < seatRankings.length; i++) {
    var pointer = (i + offset) % seatRankings.length;
    if (seatRankings[pointer].value !== activeSeat) {
      return seatRankings[pointer].value;
    }
  }
  throw new Error('cant find next seat');
};

export const shiftViewPointInDirection = (viewpoint) => {
  switch (viewpoint) {
    case 'S':
      return 'E';
    case 'E':
      return 'N';
    case 'N':
      return 'W';
    case 'W':
      return 'S';
    default:
      throw new Error('viewpoint must be one of N, S, E or W');
  }
};

export const arrangeSeatsBasedOnViewPoint = (viewpoint) => {
  switch (viewpoint) {
    case 'S':
      return [
        { key: 'North', value: 'N' },
        { key: 'East', value: 'E' },
        { key: 'South', value: 'S' },
        { key: 'West', value: 'W' }
      ];
    case 'E':
      return [
        { key: 'West', value: 'W' },
        { key: 'North', value: 'N' },
        { key: 'East', value: 'E' },
        { key: 'South', value: 'S' }
      ];
    case 'N':
      return [
        { key: 'South', value: 'S' },
        { key: 'West', value: 'W' },
        { key: 'North', value: 'N' },
        { key: 'East', value: 'E' }
      ];
    case 'W':
      return [
        { key: 'East', value: 'E' },
        { key: 'South', value: 'S' },
        { key: 'West', value: 'W' },
        { key: 'North', value: 'N' }
      ];
    default:
      throw new Error('viewpoint must be one of N, S, E or W');
  }
};

export const randomDeal = () => {
  // create a deal of 52 randomly selcted cards
  let deal = [];
  // create 52 cards array
  let cards = allCards.slice(); // use slice to copy array
  _.each(['N', 'E', 'S', 'W'], seat => {
    for (let i = 0; i < 13; i++) {
      // create random number between 0 and 51 (cards.length)
      const rndm = _.random(0, cards.length - 1);
      // pull card at that position and add to deal
      const pulled = _.pullAt(cards, rndm)[0];
      const cardObj = convertCodeToCardObject(pulled, seat);
      deal.push(cardObj);
    }
  });
  return deal;
};

export const completeFinalSeatsDeal = (deal, remainingCards) => {
  // if there is only 1 seat that hasn't been populated fully (less than 13 cards)
  // then automatically populate this seat with remaining cards
  // first check remainingCards is 13 or less (the minimum amount of cards dealt to fill 3 seats)
  if (remainingCards.length <= 13) {
    const counts = [];
    _.each(['N', 'E', 'S', 'W'], seat => {
      const cardsForSeat = getCardsForSeat(deal, seat);
      counts.push({
        seat: seat,
        count: cardsForSeat.length
      })
    });
    const seatsWithSpaces = _.filter(counts, s => { return s.count < 13; });
    if (seatsWithSpaces.length === 1) {
      // populate the remaining seat
      _.each(remainingCards, card => {
        // add card to deal
        const cardObj = convertCodeToCardObject(card, seatsWithSpaces[0].seat);
        deal.push(cardObj);
      });
    }
  }
  return deal;
};

export const getOrderOfSuitsForCardList = (contract, index, isStack) => {
  const suits = ['S', 'H', 'C', 'D'];

  if (contract.denomination !== 'NT') {

    // we always want to show trump leftmost from the player's perspective,
    // except with dummy / stack cards which should show trumps leftmost from *declarer*'s perspective
    // (because of how stack gets rendered that just means
    // making sure trumps are at the bottom at index === 3)
    let suitsIndex = 0;
    if (isStack && index === 3) {
      suitsIndex = 3;
    }
    // rotate the suits so the trump suit is on the left
    while (contract.denomination !== suits[suitsIndex]) {
      arrayRotate(suits, false);
    }
  }
  return suits;
};

// is the card that the user has played touching the card the author has chosen?
// e.g. When the author has chosen say the H8 as the card the user should play,
// and the user has a deal of SK, ST, H8, H7, H6, C3, D6 the user could play
// H7 or H6 and it would be a touching card
export const isTouchingCard = (authorsCard, usersCard, cards) => {
  let isTouchingCard = false;
  // firstly are they the same suit?
  if (authorsCard.suit === usersCard.suit) {

    // get the cards that are equal to the authors card suit
    const suitCards = _.filter(cards, { suit: authorsCard.suit });

    // check all the higher touching cards
    let touchingCard = checkTouchingCard(authorsCard, suitCards, 'higher');
    while(touchingCard) {
      if (usersCard.rank === touchingCard.rank) {
        isTouchingCard = true;
        break;
      }
      touchingCard = checkTouchingCard(touchingCard, suitCards, 'higher');
    }

    // check all the lower touching cards
    touchingCard = checkTouchingCard(authorsCard, suitCards, 'lower');
    while(touchingCard) {
      if (usersCard.rank === touchingCard.rank) {
        isTouchingCard = true;
        break;
      }
      touchingCard = checkTouchingCard(touchingCard, suitCards, 'lower');
    }
  }
  return isTouchingCard;
};

export const checkTouchingCard = (card, cards, direction) => {
  // get the ranking/sort of the current card
  const cardRanking = _.find(cardRankings, { value: card.rank }).sort;

  // get the value of the next card up/down
  const touchingCardRank = _.find(
    cardRankings,
    { sort: direction === 'higher' ? cardRanking - 1 : cardRanking + 1 }
  );

  let touchingCard = undefined;
  if (touchingCardRank) {
    // get the card from the cards that has this value (if there is one)
    touchingCard = _.find(cards, { suit: card.suit, rank: touchingCardRank.value });
  }

  return touchingCard;
};
