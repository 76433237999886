// generally useful helper functions... go

// super useful function - https://stackoverflow.com/a/23368052
export const arrayRotate = (arr, reverse) => {
  if (reverse) {
    arr.push(arr.shift());
  } else {
    arr.unshift(arr.pop());
  }
  return arr;
};

// strip the html from text
export const stripHtml = (text) => {
  let noHtmlText = text.replace(/<\/?[^>]+(>|$)/g, '');
  noHtmlText = noHtmlText.replace(/&nbsp;/g, '');
  return noHtmlText.trim();
};
