import { winnerOfHand, flagWinningCard } from '../helpers/play.js';
import { getNextSeatForPlay } from '../helpers/card.js';

// add the card just played to the tricks for a table
// assumes the card played is legal
// returns an updated table object
export const addToCardsPlayed = (inputCard, table) => {
  // make sure any subsequent operations don't update table object in-place
  // (important when using this helper in React)
  table = { ...table };

  if (table.cardPlay) {
    table.cardPlay = table.cardPlay.concat();
  } else {
    table.cardPlay = [];
  }

  const card = {
    rank: inputCard.rank,
    suit: inputCard.suit,
    seat: table.activeSeat
  };

  let lastTrick = table.cardPlay[table.cardPlay.length - 1];

  // record the order in which this card was played
  if (lastTrick) {
    const numCompleteTricks = Math.max(table.cardPlay.length - 1, 0);
    card.step = numCompleteTricks * 4 + lastTrick.cards.length;
  } else {
    card.step = 0;
    // record the first card played for the results page
    table.leadPlay = { rank: card.rank, suit: card.suit };
  }

  // if the last trick already contains 4 cards or no plays have been made yet
  // create a new trick
  if (!lastTrick || lastTrick.cards.length === 4) {
    lastTrick = {
      index: table.cardPlay.length,
      cards: [card]
    };

    table.cardPlay.push(lastTrick);
  } else {
    const cards = [...lastTrick.cards, card];
    lastTrick = { ...lastTrick, cards };

    table.cardPlay[table.cardPlay.length - 1] = lastTrick;
  }

  // finally, if the trick has just been completed, mark the winning card
  // and record the win
  if (lastTrick && lastTrick.cards.length === 4) {
    const winner = winnerOfHand(lastTrick.cards, table.contract.denomination);
    flagWinningCard(lastTrick.cards, winner);

    // winner plays first in the next trick
    table.activeSeat = winner;

    if (winner === 'N' || winner === 'S') {
      table.nsTricks += 1;
    } else {
      table.ewTricks += 1;
    }
  } else {
    // advance active seat on table
    table.activeSeat = getNextSeatForPlay(table.activeSeat);
  }

  return table;
};
